<!-- eslint-disable vue/no-v-html -->
<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="550px"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                class="mr-4"
                color="vdPrimary"
                dark
                v-on="on"
            >
                Criar
            </v-btn>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Evento"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="form"
                v-model="valid"
                style="padding: 1.5rem"
                lazy-validation
            >
                <v-row>
                    <v-col cols="12">
                        <BaseTextField
                            v-model="payload.ds_titulo"
                            color="vdPrimary"
                            label="Título *"
                            validation-empty
                        />
                    </v-col>
                    <v-col cols="12">
                        <BaseTextField
                            v-model="payload.ds_descricao"
                            color="vdPrimary"
                            label="Descricao"
                        />
                    </v-col>
                    <v-col cols="3">
                        <BaseDatePickerFormatted
                            v-model="handleDtInicio"
                            label="Data Início *"
                            validation-empty
                        />
                    </v-col>
                    <v-col cols="3">
                        <BaseTextField
                            v-model="payload.nr_inicio"
                            v-mask="'##:##'"
                            color="vdPrimary"
                            label="Hora início *"
                            validation-empty
                        />
                    </v-col>
                    <v-col cols="3">
                        <BaseTextField
                            v-model="payload.nr_final"
                            v-mask="'##:##'"
                            color="vdPrimary"
                            label="Hora final *"
                            validation-empty
                        />
                    </v-col>
                    <v-col
                        v-show="getMostrarDataFinal"
                        cols="3"
                    >
                        <BaseDatePickerFormatted
                            v-model="handleDtFinal"
                            label="Data Final *"
                            validation-empty
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="payload.sn_semanalmente"
                            label="Repetir semanalmente"
                            hide-details
                        />
                    </v-col>
                    <v-col cols="12">
                        <BaseAutoComplete
                            v-model="payload.cd_rotina_categoria"
                            label="Categoria"
                            item-value="cd_rotina_categoria"
                            item-text="ds_descricao"
                            hide-no-data
                            clearable
                            :items="categorias"
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import dayjs from "dayjs";
import { defineComponent } from "vue";

const defaultPayload = {
    sn_semanalmente: false,
    ds_titulo: "",
    ds_descricao: null,
    dt_inicio: null,
    dt_final: null,
    nr_inicio: null,
    nr_final: null,
    cd_rotina_categoria: null,
};

export default defineComponent({
    props: {
        cdEstudoTopico: {
            type: Number,
            default: -1,
        },
    },

    data() {
        return {
            payload: { ...defaultPayload },
            valid: true,
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
            categorias: [],
        };
    },

    computed: {
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
        handleDtInicio: {
            get() {
                if (this.payload.dt_inicio) {
                    const [ano, mes, dia] = this.payload.dt_inicio.split("-");
                    return `${dia}/${mes}/${ano}`;
                }
                return this.getDateDefault();
            },
            set(dt_inicio) {
                if (dt_inicio) {
                    const [dia, mes, ano] = dt_inicio.split("/");
                    const dt_inicio_formatada = `${ano}-${mes}-${dia}`;
                    this.payload.dt_inicio = dt_inicio_formatada;
                } else {
                    this.payload.dt_inicio = dt_inicio;
                }
            },
        },
        handleDtFinal: {
            get() {
                if (this.payload.dt_final) {
                    const [ano, mes, dia] = this.payload.dt_final.split("-");
                    return `${dia}/${mes}/${ano}`;
                }
                return this.getDateDefault();
            },
            set(dt_final) {
                if (dt_final) {
                    const [dia, mes, ano] = dt_final.split("/");
                    const dt_final_formatada = `${ano}-${mes}-${dia}`;
                    this.payload.dt_final = dt_final_formatada;
                } else {
                    this.payload.dt_final = dt_final;
                }
            },
        },
        getMostrarDataFinal() {
        // Parse a data inicial e hora de início
            const startDateTime = dayjs(`${this.payload.dt_inicio} ${this.payload.nr_inicio}`);

            // Verificar se a hora de fim é no próximo dia
            let endDateTime = dayjs(`${this.payload.dt_final} ${this.payload.nr_final}`);
            if (endDateTime.isBefore(startDateTime)) {
                // Caso a hora final seja antes da hora inicial, assumimos que seja no dia seguinte
                endDateTime = endDateTime.add(1, "day");
            }

            // Obter a meia-noite do dia seguinte
            const nextMidnight = dayjs(startDateTime).add(1, "day").startOf("day");

            // Retorna true se a hora final for maior que meia-noite do próximo dia
            return endDateTime.isSameOrAfter(nextMidnight);
        },
    },

    async mounted() {
        const [dia, mes, ano] = this.getDateDefault().split("/");
        const dt_data_formatada = `${ano}-${mes}-${dia}`;
        this.payload.dt_inicio = dt_data_formatada;
        this.categorias = await this.$rotinaCategoriaHttpGateway.getAll();
    },

    methods: {
        // utilizado via ref
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.ds_nome_acao = "Editar";
                this.payload = {
                    ...objPayload,
                    dt_inicio: this.getDateYearMonthDay(),
                    dt_final: this.getDateYearMonthDay(),
                };
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.form.validate();
        },

        resetValidation() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },

        timeStringToSeconds(timeString) {
            // Verifica se o formato da string é HH:mm:ss
            const regex = /^(\d{2}):(\d{2}):(\d{2})$/;
            const match = timeString.match(regex);

            if (!match) {
                throw new Error("Formato de string inválido. Utilize HH:mm:ss");
            }

            // Extrai horas, minutos e segundos da string
            const [, hours, minutes, seconds] = match;

            // Converte horas, minutos e segundos para segundos
            const SECONDS_PER_HOUR = 3600;
            const SECONDS_PER_MINUTE = 60;

            const hoursInSeconds = Number(hours) * SECONDS_PER_HOUR;
            const minutesInSeconds = Number(minutes) * SECONDS_PER_MINUTE;
            const secondsNumber = Number(seconds);
            const totalSeconds = hoursInSeconds + minutesInSeconds + secondsNumber;

            return totalSeconds;
        },

        horaParaSegundos(horaString) {
            return `${horaString}:00`;
        },

        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                try {
                    this.sn_loading = true;
                    const ds_nome_acao = "criado";
                    const payloadFormatado = {
                        ...this.payload,
                        nr_inicio: this.horaParaSegundos(this.payload.nr_inicio),
                        nr_final: this.horaParaSegundos(this.payload.nr_final),
                    };
                    if (this.payload.sn_semanalmente) {
                        payloadFormatado.ds_repeticao = "WEEKLY";
                    }
                    await this.$rotinaHttpGateway.create(payloadFormatado);
                    this.$emit("atualizar-listagem");
                    this.$toast.success(`Evento ${ds_nome_acao} com sucesso!`);
                    this.sn_loading = false;
                    this.fecharModal();
                } catch (e) {
                    this.sn_loading = false;
                }
            }
        },

        resetData() {
            this.payload = {
                ...defaultPayload,
                dt_inicio: this.getDateYearMonthDay(),
                dt_final: this.getDateYearMonthDay(),
            };
        },

        getDateYearMonthDay() {
            return this.$dayjs().format("YYYY-MM-DD");
        },

        getDateDefault() {
            return this.$dayjs().format("DD/MM/YYYY");
        },
    },
});
</script>
