<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
    <div class="rotina">
        <BaseHeaderPage />
        <v-sheet>
            <v-toolbar flat>
                <RotinaModalSalvar @atualizar-listagem="getEventos" />
                <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                >
                    Hoje
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="prev"
                >
                    <v-icon small>
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="next"
                >
                    <v-icon small>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title>
                    {{ getTitle }}
                </v-toolbar-title>
                <div class="d-flex ml-8">
                    <div
                        v-for="(detail, index) in details"
                        :key="detail.cd_rotina_categoria"
                        class="d-flex"
                    >
                        <div
                            class="d-flex align-center mr-3"
                            style="height: 24px;"
                        >
                            <div
                                style="width: 8px; height: 8px; border-radius: 100%;"
                                :style="{ 'backgroundColor': detail.ds_cor }"
                            />
                        </div>
                        <div class="d-flex flex-column">
                            <span><strong>{{ detail.ds_descricao }}</strong></span>
                            <span>{{ detail.total_tempo }}</span>
                        </div>
                        <v-divider
                            v-if="index < details.length - 1"
                            vertical
                            class="mx-8"
                        />
                    </div>
                </div>
                <v-spacer />
            </v-toolbar>

            <v-calendar
                ref="calendar"
                v-model="value"
                color="primary"
                type="day"
                locale="pt-br"
                :events="eventos"
                :event-ripple="false"
                :interval-format="getIntervalFormat"
            />
        </v-sheet>
    </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData";
import timezone from "dayjs/plugin/timezone";
import RotinaModalSalvar from "../components/RotinaModalSalvar.vue";
import "dayjs/locale/pt";

dayjs.extend(localeData);

dayjs.locale("pt"); // Muda o local para português, por exemplo

dayjs.extend(utc);
dayjs.extend(timezone);
const FORMAT_STRING = "DD [de] MMMM [de] YYYY (dddd)";

export default {
    components: {
        RotinaModalSalvar,
    },
    data: () => ({
        value: "",
        events: [],
        details: [],
        colors: ["#2196F3", "#3F51B5", "#673AB7", "#00BCD4", "#4CAF50", "#FF9800", "#757575"],
        names: ["Meeting", "Holiday", "PTO", "Travel", "Event", "Birthday", "Conference", "Party"],
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
        menu: false,
        x: 0,
        y: 0,
        eventos: [],
    }),
    computed: {
        getTitle() {
            if (this.value) {
                return dayjs(this.value).format(FORMAT_STRING);
            }
            return dayjs().format(FORMAT_STRING);
        },
    },
    mounted() {
        const dateNow = this.$dayjs().format("YYYY-MM-DD");
        this.value = dateNow;
        this.getEventos();
    },
    methods: {
        setToday() {
            this.value = "";
        },
        prev() {
            this.$refs.calendar.prev();
            this.getEventos(this.value);
        },
        next() {
            this.$refs.calendar.next();
            this.getEventos(this.value);
        },
        getIntervalFormat(params) {
            return params.time;
        },
        getDate(dateString) {
            return dateString.split("T")[0];
        },
        removeMilliseconds(dateString) {
            return dateString.replace(".000Z", "");
        },
        getDateComplete(date, hour) {
            return dayjs(`${date} ${hour}`).format("YYYY-MM-DD HH:mm");
        },
        getDayjsInstance(string) {
            return dayjs(string);
        },
        formatYearMonthDay(dayjsInstance) {
            return dayjsInstance.format("YYYY-MM-DD");
        },
        formatHourMinute(dayjsInstance) {
            return dayjsInstance.format("HH:mm");
        },
        getDateCalendar(dateString, hourString) {
            const date = this.getDate(dateString);
            const dateWithoutMilliseconds = this.removeMilliseconds(date);
            const dateDayjs = this.getDayjsInstance(dateWithoutMilliseconds);
            const dateFormatted = this.formatYearMonthDay(dateDayjs);

            const hourWithoutMilliseconds = this.removeMilliseconds(hourString);
            const hourDayjs = this.getDayjsInstance(hourWithoutMilliseconds);
            const hourFormatted = this.formatHourMinute(hourDayjs);

            return {
                dateDayjs,
                hourFormatted,
                dateFormatted: this.getDateComplete(dateFormatted, hourFormatted),
            };
        },
        async getEventos(date = null) {
            const currentDate = date ?? this.$dayjs().format("YYYY-MM-DD");
            this.details = await this.$rotinaHttpGateway.dashboard(currentDate);
            const data = await this.$rotinaHttpGateway.findAll(currentDate);
            const formattedEvents = data.flatMap((item) => {
                const events = [];

                const {
                    dateDayjs: dateInicioDayjs,
                    dateFormatted: start,
                    hourFormatted: horaInicio,
                } = this.getDateCalendar(
                    item.dt_inicio,
                    item.nr_inicio,
                );
                const {
                    dateDayjs: dateFinalDayjs,
                    dateFormatted: end,
                    hourFormatted: horaFinal,
                } = this.getDateCalendar(
                    item.dt_final ?? item.dt_inicio,
                    item.nr_final,
                );

                const name = `${item.ds_titulo} (${item.rotina_categoria.ds_descricao})`;

                if (item.ds_repeticao === "NONE") {
                    events.push({
                        name,
                        start,
                        end,
                        details: item.ds_descricao,
                        color: item.rotina_categoria.ds_cor,
                    });
                }

                if (item.ds_repeticao === "WEEKLY") {
                    const dateCalendarDayjs = this.getDayjsInstance(this.value);
                    const dtInicioWeekly = this.formatYearMonthDay(dateCalendarDayjs);
                    const isAfter = dateFinalDayjs.isAfter(dateInicioDayjs);
                    const dtInicioDayjs = dateCalendarDayjs.clone();
                    const dtFinalDayjs = dateCalendarDayjs;
                    const dtFinalWeekly = this.formatYearMonthDay(dtFinalDayjs);
                    if (isAfter && dtInicioDayjs.isAfter(dateInicioDayjs)) {
                        const startWeeklyFirstFormatted = this.formatYearMonthDay(
                            dtInicioDayjs.subtract(1, "day"),
                        );

                        const startWeeklyFirst = this.getDateComplete(
                            startWeeklyFirstFormatted,
                            horaInicio,
                        );
                        const endWeeklyFirst = this.getDateComplete(dtFinalWeekly, horaFinal);
                        events.push({
                            name,
                            start: startWeeklyFirst,
                            end: endWeeklyFirst,
                            details: item.ds_descricao,
                            color: item.rotina_categoria.ds_cor,
                        });
                    }
                    const startWeekly = this.getDateComplete(
                        dtInicioWeekly,
                        horaInicio,
                    );
                    let numeroDias = 0;
                    if (isAfter) {
                        numeroDias = 1;
                    }
                    const dtFinalDayjs2 = dateCalendarDayjs.add(numeroDias, "day");
                    const dtFinalWeekly2 = this.formatYearMonthDay(dtFinalDayjs2);
                    const endWeekly = this.getDateComplete(
                        dtFinalWeekly2,
                        horaFinal,
                    );
                    events.push({
                        name,
                        start: startWeekly,
                        end: endWeekly,
                        details: item.ds_descricao,
                        color: item.rotina_categoria.ds_cor,
                    });
                }

                return events;
            });
            this.eventos = formattedEvents;
        },
    },
};
</script>

<style scoped lang="scss">
.rotina {
    $ref: &;

    .v-calendar {
        border: none;
        border: #e0e0e0 1px solid;
        padding: 4px 0;
    }

    display: flex;
    flex-direction: column;
    row-gap: 24px;

    &__header {
        margin-bottom: 0.75rem;

        #{$ref}__actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 16px;
            height: 100%;
        }
    }
}
</style>
