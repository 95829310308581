export default class RotinaHttpGateway {
    httpClient;

    basePath = "/rotina";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async findAll(dt_data = null) {
        const { data } = await this.httpClient.get(this.basePath, {
            params: {
                dt_data
            }
        });
        return data;
    }

    async create(payload) {
        const { data } = await this.httpClient.post(this.basePath, payload);
        return data;
    }

    async dashboard(dt_data) {
        const { data } = await this.httpClient.get(`${this.basePath}/dashboard?dt_data=${dt_data}`);
        return data;
    }
}
