import { configuracoesRoutes } from "@module/Configuracoes";
import { controleDiarioRoutes } from "@module/ControleDiario";
import { controleMensalRoutes } from "@module/ControleMensal";
import { cartaoCreditoRoutes } from "@module/CartaoCredito";
import { cuboMagicoRoutes } from "@module/CuboMagico";
import { estudosRoutes } from "@module/Estudos";
import { cofrinhosRoutes } from "@module/Cofrinhos";
import { leagueOfLegendsRoutes } from "@module/LeagueOfLegends";
import { rotinaRoutes } from "@module/Rotina";

import registerRoutes from "./registerRoutes";

export default function routesFactory() {
    registerRoutes(cartaoCreditoRoutes);
    registerRoutes(controleDiarioRoutes);
    registerRoutes(controleMensalRoutes);
    registerRoutes(cuboMagicoRoutes);
    registerRoutes(estudosRoutes);
    registerRoutes(cofrinhosRoutes);
    registerRoutes(leagueOfLegendsRoutes);
    registerRoutes(rotinaRoutes);
    registerRoutes(configuracoesRoutes);
}
