import TheDefaultVue from "../Main/layouts/TheDefault.vue";
import RotinaView from "./views/RotinaView.vue";

export default [
    {
        path: "/rotina",
        name: "rotina",
        component: TheDefaultVue,
        meta: {
            icon: "mdi-calendar-month",
            viewMenu: true,
            title: "Rotina",
        },
        redirect() {
            return { name: "rotina-parent" };
        },
        children: [
            {
                path: "",
                name: "rotina-parent",
                component: RotinaView,
                meta: {
                    icon: "mdi-calendar-month",
                    viewMenu: false,
                    title: "Rotina",
                },
            },
        ],
    },
];
